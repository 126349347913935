<template>
  <div>
    <div
      v-if="
        typeof error_messages === 'object' &&
        Object.keys(error_messages).length > 0
      "
      class="alert alert-danger"
    >
      <ul>
        <template v-for="(error, i) in error_messages">
          <span v-bind:key="i">
            <li v-for="(message, ii) in error" v-bind:key="ii">
              {{ message }}
            </li>
          </span>
        </template>
      </ul>
    </div>
    <v-btn
      :to="{ name: 'materialRequestCreateUpdate' }"
      class="btn btn-success btn-elevate kt-login__btn-primary text-white"
      >{{ $t("MENU.ADD") }} {{ $t("BUTTON.NEW") }}
      {{ $t("COMPONENTS.MATERIALREQUEST") }}</v-btn
    >

    <v-container>
      <v-row no-gutters>
        <v-col cols="4">
          <v-text-field
            v-model="headers.search"
            prepend-inner-icon="search"
            clearable
            :label="$t('A.SEARCH')"
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <!-- <v-checkbox v-model="tableOptions.showSearch" 
                      label="Търси по колона" 
                      class="shrink"></v-checkbox>  -->
        </v-col>

        <v-col class="text-right" cols="2">
          <v-select
            v-model="headers.rows"
            :items="tableOptions.rowsPerPage"
            :label="$t('A.PER_PAGE')"
            chips
            attach
          ></v-select>
        </v-col>
      </v-row>

      <v-row class="mb-6 t-header">
        <template v-for="column of headers.columns">
          <v-col :key="column.name" :cols="column.cols" v-if="!column.hide">
            <span @click="changeOrder(column.name)">
              {{ $t("LABELS." + column.name) }}
              <span v-if="column.name == headers.order">{{
                orderIcon
              }}</span></span
            >
          </v-col>
        </template>

        <v-col cols="3"> Изпратен имейл </v-col>
        <v-col cols="4"> Операции </v-col>
      </v-row>

      <div v-show="!isLoadingMaterialRequest">
        <v-row class="mb-6 t-row" v-for="item of items" :key="item.id">
          <template v-for="col of headers.columns">
            <v-col
              class="t-col"
              :key="col.name"
              :cols="col.cols"
              v-if="!col.hide"
            >
              {{ item[col.name] }}
            </v-col>
          </template>
          <v-col cols="3" class="t-col col">
            {{ item.emailSend ? "Да" : "Не" }}
          </v-col>
          <v-col
            cols="4"
            class="t-col d-flex justify-content-start operation-container flex-wrap"
          >
            <EditButton
              v-if="
                item.acceptanceActId == 0 ||
                user.role == 'Manager' ||
                user.role == 'Qualified' ||
                user.role == 'Admin'
              "
              :id="item.id"
              action-name="materialRequestCreateUpdate"
            />
            <v-tooltip top v-if="item.acceptanceActId == 0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-2 mt-2"
                  fab
                  dark
                  x-small
                  color="#3dbdad"
                  v-bind="attrs"
                  v-on="on"
                  @click="getData(item.id)"
                >
                  <v-icon> fas fa-file-signature </v-icon>
                </v-btn>
              </template>
              <span>Създай ППП</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-2 mt-2"
                  fab
                  dark
                  x-small
                  color="#3dbdad"
                  v-bind="attrs"
                  v-on="on"
                  @click="openEmailsDialog(item.id, item.number)"
                >
                  <v-icon> fas fas fa-envelope </v-icon>
                </v-btn>
              </template>
              <span>Изпрати имейл</span>
            </v-tooltip>
            <DownloadPdfButton
              v-if="item.acceptanceActId > 0"
              :id="item.acceptanceActId"
              file-type="acceptance-act"
              text="Свали ППП"
              list-action="materialRequestList"
            />
            <DownloadPdfButton
              :id="item.id"
              file-type="material-request"
              text="Изтегли бланка ЗАЯВКА"
              list-action="materialRequestList"
            />
            <LogLink :id="item.id" type="material-request" class-name="row" />
            <!-- <a href="#" class="float-right" @click="deleteData(item)">Изтрий</a> -->
            <DeleteButton
              v-if="user.role == 'Admin' || user.role == 'Qualified'"
              :id="item.id"
              :delete-action="deleteAction"
              :fetch-data="fetchData"
              @response-errors="error_messages = $event"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-right">
            {{ totalItems }} {{ $t("A.RESULTS") }}
          </v-col>
        </v-row>
      </div>

      <v-progress-linear
        class="mt-5"
        color="#25bdad"
        buffer-value="0"
        stream
        reverse
        :active="isLoadingMaterialRequest"
      ></v-progress-linear>

      <Paginator
        :totalPages="totalPages"
        :page="headers.page"
        :maxVisibleButtons="tableOptions.maxVisibleButtons"
        @change-page="changePage"
      />
    </v-container>

    <v-dialog v-model="ppp_dialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Създай Приемно-предавателен протокол от тази заявка
        </v-card-title>

        <v-card-text>
          <div
            v-if="
              typeof error_messages === 'object' &&
              Object.keys(error_messages).length > 0
            "
            class="alert alert-danger"
          >
            <ul>
              <template v-for="(error, k) in error_messages">
                <span v-bind:key="k">
                  <li v-for="(message, i) in error" v-bind:key="i">
                    {{ message }}
                  </li>
                </span>
              </template>
            </ul>
          </div>

          <v-row>
            <v-col cols="12">
              <h4>Дата:</h4>
              <datetime
                zone="local"
                value-zone="local"
                type="date"
                v-model="ppp_dialog_item.date"
                input-class="form-control"
              ></datetime>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="saveAcceptanceAct()"> Създай </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="emailsDialog.display" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Изпращане на имейл
        </v-card-title>

        <v-card-text>
          <div
            v-if="
              typeof error_messages === 'object' &&
              Object.keys(error_messages).length > 0
            "
            class="alert alert-danger"
          >
            <ul>
              <template v-for="(error, i) in error_messages">
                <span v-bind:key="i">
                  <li v-for="(message, ii) in error" v-bind:key="ii">
                    {{ message }}
                  </li>
                </span>
              </template>
            </ul>
          </div>
          <vue-form-generator
            ref="form"
            :schema="emailsDialogSchema"
            :model="emailsDialog"
          ></vue-form-generator>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="sendEmails()"> Изпрати </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";

import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

import VueFormGenerator from "vue-form-generator";
import "vue-form-generator/dist/vfg.css"; // optional full css additions

import {
  GET_MATERIALREQUESTS,
  DELETE_MATERIALREQUEST,
  GET_MATERIALREQUEST_NO_LOAD,
} from "@/store/materialRequest.module";

import { mapGetters } from "vuex";

import { SAVE_ACCEPTANCEACT } from "@/store/acceptanceActs.module";
import { GET_ALL_EMAILS, SEND_EMAIL } from "@/store/emails.module";

import Paginator from "@/components/Paginator";

import ListMixin from "@/common/mixins/List";
import LogLink from "@/components/Log/LogLink";
import DownloadPdfButton from "@/components/ActionButtons/DownloadPdfButton";
import EditButton from "@/components/ActionButtons/EditButton";
import DeleteButton from "@/components/ActionButtons/DeleteButton";

export default {
  name: "ListMaterialRequest",

  components: {
    DownloadPdfButton,
    EditButton,
    LogLink,
    Paginator,
    DeleteButton,
    "vue-form-generator": VueFormGenerator.component,
  },
  mixins: [ListMixin],
  data() {
    return {
      action: "materialRequest",
      deleteAction: DELETE_MATERIALREQUEST,
      items: [],
      totalItems: 0,
      error_messages: [],
      ppp_dialog: false,
      ppp_dialog_item: {},
      headers: {
        columns: [
          {
            name: "id",
            cols: "1",
          },
          {
            name: "number",
            search: "",
          },
          {
            name: "date",
          },
          {
            name: "acceptance_act_id",
            hide: true,
          },
          {
            name: "email_send",
            hide: true,
          },
        ],
        search: "",
        rows: 10,
        page: 1,
        order: "id",
        orderType: "asc",
      },
      emailsDialog: {
        id: null,
        display: false,
        message: null,
        sendTo: [],
      },
      emails: [],
      emailsDialogSchema: {
        fields: [
          {
            type: "textArea",
            inputType: "text",
            label: "Съобщение",
            model: "message",
            required: true,
            validator: VueFormGenerator.validators.string.locale({
              fieldIsRequired: "Полето е задължително !!!",
            }),
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["isLoadingMaterialRequest"]),
    orderIcon: function () {
      let vm = this;

      return vm.headers.orderType == "asc" ? "▲" : "▼";
    },
    user: function () {
      return JSON.parse(window.localStorage.getItem("userData"));
    },
  },
  watch: {
    "headers.search": _.debounce(function (new_value) {
      let vm = this;

      vm.headers.page = 1;
      if (new_value == null || new_value.length >= 3) {
        vm.fetchData();
      }
    }, 250),
    "headers.rows": _.debounce(function (new_value) {
      let vm = this;
      new_value = parseInt(new_value);
      window.localStorage.setItem(vm.action + "_rows", new_value);

      vm.headers.page = 1;
      vm.fetchData();
    }, 250),
    "headers.page": _.debounce(function () {
      let vm = this;
      vm.fetchData();
    }, 250),
  },
  mounted() {
    let vm = this;

    // vm.$store.dispatch(SET_BREADCRUMB, [{ title: vm.$i18n.t("COMPONENTS.MATERIALREQUEST") }]);
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Приемно-предавателен протокол" },
    ]);

    vm.fetchData();
  },
  methods: {
    openEmailsDialog: function (id, number) {
      let vm = this;

      vm.emailsDialog.id = id;
      vm.emailsDialog.message = `Здравейте, прилагаме заявка номер: ${number}`;
      vm.emailsDialog.display = true;
      if (vm.emails.length === 0) {
        vm.getEmails();
      }
    },
    sendEmails: function () {
      let vm = this;

      vm.$store
        .dispatch(SEND_EMAIL, { payload: vm.emailsDialog })
        .then((data) => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message,
          });

          vm.emailsDialog.display = false;
          vm.emailsDialog.sendTo = [];

          vm.fetchData();
        })
        .catch((response) => {
          vm.error_messages = response.data.errors;
        });
    },
    getEmails: function () {
      let vm = this;

      vm.$store.dispatch(GET_ALL_EMAILS).then((data) => {
        vm.$nextTick(function () {
          vm.emails = data.data;

          let emailsField = {
            type: "vueMultiSelect",
            label: "Имейли",
            placeholder: "Избери имейли",
            model: "sendTo",
            required: true,
            values: vm.emails,
            selectOptions: {
              trackBy: "id",
              multiSelect: true,
              multiple: true,
              closeOnSelect: false,
              customLabel: (op) => op.email,
            },
            validator: (value) => {
              return !value || !value.length
                ? ["Полето е задължително !!!"]
                : [];
            },
          };

          vm.emailsDialogSchema.fields.push(emailsField);
        });
      });
    },
    saveAcceptanceAct: function () {
      let vm = this;
      var action = SAVE_ACCEPTANCEACT;

      for (let i in vm.ppp_dialog_item.details) {
        vm.ppp_dialog_item.details[i].buy = moment(
          vm.ppp_dialog_item.details[i].buy
        ).format("YYYY-MM-DD");
        vm.ppp_dialog_item.details[i].sell = moment(
          vm.ppp_dialog_item.details[i].sell
        ).format("YYYY-MM-DD");

        // to fix
        vm.ppp_dialog_item.details[i].expirationDate = moment(
          vm.ppp_dialog_item.details[i].expirationDate
        ).format("YYYY-MM-DD");
      }

      vm.ppp_dialog_item.date = moment(vm.ppp_dialog_item.date).format(
        "YYYY-MM-DD"
      );

      vm.ppp_dialog_item.materialRequestId = vm.ppp_dialog_item.id;

      this.error_messages = [];
      vm.$store
        .dispatch(action, { payload: vm.ppp_dialog_item })
        .then((data) => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message,
          });

          vm.$nextTick(function () {
            vm.fetchData();

            vm.ppp_dialog_item = {};
            vm.ppp_dialog = false;
          });
        })
        .catch((response) => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300,
          });

          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message,
            });
            vm.$router.push({ name: "materialRequestsList" });
          }
        });
    },
    getData: function (id) {
      let vm = this;
      this.error_messages = [];

      this.$store
        .dispatch(GET_MATERIALREQUEST_NO_LOAD, id)
        .then((data) => {
          vm.$nextTick(function () {
            vm.ppp_dialog_item = data.data;
            vm.ppp_dialog = true;
          });
        })
        .catch((response) => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300,
          });

          if (typeof response === "undefined" || response.status === 404) {
            vm.$notification["error"]({
              message: "Грешка 404",
              description: "Страницата не е намерена!",
            });
            vm.$router.push({ name: "materialRequestsList" });
          }
        });
    },
    getOptions: function () {
      let vm = this;

      vm.headers.order =
        window.localStorage.getItem(vm.action + "_order") || "id";
      vm.headers.orderType =
        window.localStorage.getItem(vm.action + "_orderType") || "asc";
      vm.headers.rows = parseInt(
        window.localStorage.getItem(vm.action + "_rows") || 10
      );
    },
    changeOrder: _.debounce(function (key) {
      let vm = this;

      if (vm.headers.order == key) {
        let oType = vm.headers.orderType == "asc" ? "desc" : "asc";

        vm.headers.orderType = oType;
      } else {
        vm.headers.order = key;
      }

      vm.headers.page = 1;
      window.localStorage.setItem(vm.action + "_order", vm.headers.order);
      window.localStorage.setItem(
        vm.action + "_orderType",
        vm.headers.orderType
      );

      vm.fetchData();
    }, 250),
    fetchData: _.debounce(function () {
      let vm = this;

      vm.getOptions();

      vm.$store
        .dispatch(GET_MATERIALREQUESTS, { payload: vm.headers })
        .then((data) => {
          vm.$nextTick(function () {
            vm.items = data.data.data;
            vm.totalItems = data.data.recordsTotal;
            vm.totalPages = data.data.totalPages;
          });
        })
        .catch((response) => {
          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message,
            });
            vm.$router.push({ name: "materialRequestList" });
          }
        });
    }, 500),
    deleteData: _.debounce(function (item) {
      let vm = this;

      vm.$confirm({
        title: "Сигурен ли си, че искаш да изтриеш този запис?",
        content: "Tова дейтсвие не може да бъде отменено!",
        icon: "warning",
        okText: "Потвърди",
        cancelText: "Откажи",
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        onOk() {
          vm.execDelete(item);
        },
        onCancel() {},
      });
    }, 500),
  },
  beforeDestroy: function () {
    this.items = null;
    delete this.items;
  },
};
</script>
